import React from 'react';
import { Layout } from '../components/shared/Layout';
import { SEO } from '../components/shared/seo';

const Page: React.FC = () => {
  return (
    <Layout>
      <SEO title="Supporters" />
      <h1>Supporters</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  );
};

export default Page;
